<template>
  <section class="dashboard-header">
    <div class="container-fluid">
      <div class="card-header">
        <h2>{{msg}}</h2>
        <div></div>
      </div>

      <div id="carpeta" class="bg-white has-shadow my-3 py-4 px-4">
        <b-card header="Lista de Incidencia">
          <b-card-text>
            <b-row class>
              <b-col sm="7">
                <b-row class="my-2">
                  <b-col sm="4">
                    <label for="input-small" class="mt-1">Fecha:</label>
                  </b-col>
                  <b-col sm="5">
                    <span class="mt-1">{{ fecha | moment("MMMM - YYYY") }}</span>
                  </b-col>
                  <b-col sm="3">
                    <b-button-group class="mx-1">
                      <b-button size="sm" variant="light" v-on:click="mesAnt">&laquo;</b-button>
                      <b-button size="sm" variant="light" v-on:click="mesSig">&raquo;</b-button>
                    </b-button-group>
                  </b-col>
                </b-row>

                <b-row class="my-2">
                  <b-col sm="4">
                    <label for="input-small">Periodo:</label>
                  </b-col>
                  <b-col sm="8">
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" v-model="periodo" value="mes">
                      <label class="form-check-label" for="inlineRadio1"><small>MENSUAL</small></label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" v-model="periodo" value="anual">
                      <label class="form-check-label" for="inlineRadio2"><small>ANUAL</small></label>
                    </div>                    
                  </b-col>
                </b-row>
                
                <b-row class="my-2">
                  <b-col sm="4">
                    <label for="input-small">Región:</label>
                  </b-col>
                  <b-col sm="8">
                    <b-form-select v-model="region" :options="regiones" class></b-form-select>
                  </b-col>
                </b-row>

                <b-row class="my-2">
                  <b-col sm="4">
                    <label for="input-small">Estatus:</label>                      
                  </b-col>
                  <b-col sm="8">              
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" v-model="todos" @change="check($event)">
                      <label class="form-check-label" for="inlineRadio1"><small>Todos - </small></label>
                    </div>                    
                                          
                    <div class="form-check form-check-inline" v-for="estatus in estatuss" :key="estatus.id">
                      <input class="form-check-input" type="checkbox" name="inlineRadioOptions" :id="estatus.id" v-model="estatus.checked">
                      <label class="form-check-label" for="inlineRadio1"><small>{{estatus.name}}</small></label>
                    </div>                    
                  </b-col>
                </b-row>                
              </b-col>
              <b-col sm="5">
                
                <object
                  style="width: 97%; height: 25rem"
                  class="mx-3 my-3"
                  :data="pdf"
                  type="application/pdf"
                ></object>
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>

        <b-row class="mt-3">
          <b-col lg="2" class="pb-2">
            <b-button size="sm" v-on:click="listaincIdenciasXls">Excel</b-button>
          </b-col>
          <b-col lg="2" class="pb-2">
            <b-button size="sm" v-on:click="listaincIdencias">Imprimir</b-button>
          </b-col>
        </b-row>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import download from "downloadjs";

export default {
  data() {
    return {
      msg: "Imprimir",
      email: "",
      spiner: true,
      periodo: "mes",
      periodos: [
        { value: "TODO", text: "TODO EL MES" },
        { value: "SEMA", text: "ULTIMA SEMANA" },
        { value: "AYER", text: "DE AYER" },
        { value: "HOY", text: "DE HOY" }
      ],
      region: "TODO",
      regiones: [        
        { value: "TODO", text: "TODAS LAS REGIONES" },
        { value: "TSUR", text: "REGION SUR" },
        { value: "TNOR", text: "REGION NORTE" },
        { value: "TBAJ", text: "REGION BAJIO" },
        { value: "TPRO", text: "PROYECTOS" }
      ],
      todos: false,
      estatus: 1,      
      estatuss: [],
      /*[
        //{ value: "TODO", text: "TODOS"},
        { value: "CANCELADO", text: "CANCELADO", 'checked': false},
        { value: "CERRADO", text: "CERRADO", 'checked': false},
        { value: "NOAUTORIZADO", text: "NO AUTORIZADO", 'checked': false},
        { value: "PENDIENTE", text: "PENDIENTE", 'checked': false},
        { value: "PENDIENTEDHL", text: "PENDIENTE DHL", 'checked': false},
        { value: "COTIZACION", text: "COTIZACION", 'checked': false},
        { value: "TERMINADO", text: "TERMINADO", 'checked': true},
      ],*/
      numCarpeta: this.$route.params.numCarpeta,
      fecha: this.$moment().format("YYYY-MM-DD"),
      pdf: "",      
      pdfName: "REPORTE_INCIDENCIAS_TEMARQSA_",
      xls: "",
      xlsName: "TEMARQ_LISTA_INCIDENCIAS"
    };
  },

  mounted() {
    this.llenarTablas();
  },

  created() {    
  },
  methods: {
    llenarTablas(){
      axios.defaults.headers.common["Authorization"] = "Bearer " + this.$cookies.get("token");
      axios.get(process.env.VUE_APP_ROOT_API + "api/secure/estatus")
      .then(
          (response) => {            
            this.estatuss = response.data;
            //this.validarStatus();
            console.log(JSON.stringify(this.estatuss));
          },
          (error) => {            
          });   
    },
    mesAnt() {
      this.fecha = this.$moment(this.fecha).add(-1, "M").format("YYYY-MM-DD");
    },
    mesSig() {
      this.fecha = this.$moment(this.fecha).add(1, "M").format("YYYY-MM-DD");
    },
    check(e){
      var estatus = this.todos;
      this.estatuss.forEach(function (item, index) {
        item.checked = estatus;
      });
    },
    listaincIdenciasXls(){
      var params = {
        mes: "",
        fecha: this.fecha,
        periodo: this.periodo,
        region: this.region,
        estatus: this.estatuss
      }
      axios.post(process.env.VUE_APP_ROOT_API + "api/secure/incidencias/listaincidenciasxls", params)
        .then(
          response => {
            this.xls = "data:application/octet-stream;charset=utf-16le;base64," + response.data;
            download(this.xls, this.xlsName + '.xlsx' , "application/octet-stream");            
          },
          error => {}
        );
    },
    listaincIdencias() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$cookies.get("token");
      var params =
        "?mes=" +
        this.fecha + //.format("MMMM-YYYY ").toUpperCase() +
        "&fecha=" +
        this.fecha + //.format("YYYY-MM-DD") +
        "&region=" +
        this.region +
        "&estatus=" +
        this.estatus;

      console.log(params);

      axios
        .get(
          process.env.VUE_APP_ROOT_API +
            "api/secure/incidencias/listaincidencias" +
            params
        )
        .then(
          response => {
            this.pdf = "data:application/pdf;base64," + response.data;
          },
          error => {}
        );
    }
  }
};
</script>

<style>
  
</style>